import React, { useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';
import { Col, Row } from 'react-bootstrap';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { InputSelect, InputText } from '~/components/NovosInputs';

import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const ClassificacaoParceiro: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [codAplic, setCodAplic] = useState<number>();

  const {
    handleSubmit,
    setValue,
    reset,
    register,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const tipoClassificacao: Array<{
    value: number;
    label: string;
  }> = [
    {
      value: 7,
      label: 'Administradora de Cartão',
    },
    {
      value: 0,
      label: 'Clientes (PJ)',
    },
    {
      value: 1,
      label: 'Clientes (PF)',
    },
    {
      value: 2,
      label: 'Fornecedores',
    },
    {
      value: 6,
      label: 'Produtos',
    },
    {
      value: 3,
      label: 'Seguradora',
    },
    {
      value: 5,
      label: 'Transportadoras',
    },
    {
      value: 4,
      label: 'Vendedores',
    },
  ];

  const tipoClassficacaoOrdenada = [...tipoClassificacao].sort((a, b) =>
    a.value > b.value ? 1 : -1,
  );

  const focus = async (idOrName: string) => {
    let input = document.getElementById(idOrName);
    if (!input) input = document.querySelector(`[name="${idOrName}"]`);
    input?.focus();
  };

  useEffect(() => {
    // dar foco ao input
    setTimeout(() => {
      focus('des_classificacao');
    }, 500);
  }, []);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = async () => {
    await focus('des_classificacao');
    reset();
    setValue('tipo_classificacao', null);
    setValue('des_classificacao', '');
    setUpdate(false);
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_classificacao, des_classificacao, tipo_classificacao } = row;

    setValue(
      'tipo_classificacao',
      tipo_classificacao >= 0
        ? tipoClassificacao.find((item) => item.value === tipo_classificacao)
        : undefined,
    );
    setValue('des_classificacao', des_classificacao);
    setCodAplic(cod_classificacao);

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async () => {
    const { tipo_classificacao, des_classificacao } = getValues();
    try {
      if (
        des_classificacao.trim() === 'NENHUM' ||
        des_classificacao.trim() === 'NENHUMA' ||
        des_classificacao.trim() === 'NENHUM(A)'
      ) {
        return toast.warn(
          'Descrição contém palavra não permitida "Nenhum/Nenhuma".',
        );
      }
      if (isUpdate) {
        const { data } = await api.put(`/classificacao-parceiro/${codAplic}`, {
          tipo_classificacao: tipo_classificacao.value,
          des_classificacao: des_classificacao.trim(),
        });
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/classificacao-parceiro', {
        tipo_classificacao: tipo_classificacao.value,
        des_classificacao: des_classificacao.trim(),
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
      }
    } finally {
      setTimeout(() => {
        focus('des_classificacao');
      }, 500);
      setLoader(false);
    }
  });

  const handleDelete = useCallback(async () => {
    const { data } = await api.delete(`/classificacao-parceiro/${codAplic}`);
    resetFormData();
    setShowSearch(true);
    toast.success(data.message);
  }, [resetFormData]);

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => {
            setTimeout(() => {
              focus('des_classificacao');
            }, 500);
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
          codTela={175}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={175}
          title="Classificação de Parceiros"
          codigoRegistro={[
            {
              value: codAplic,
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <Row>
            <Col sm={12} md={9}>
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Informe a descrição da classificação de parceiros"
                name="des_classificacao"
                caseInput="upper"
                register={register}
                isError={!!errors.des_classificacao}
                control={control}
              />
            </Col>
            <Col sm={12} md={3}>
              <InputSelect
                label="Tipo"
                placeholder="Informe o Tipo "
                name="tipo_classificacao"
                register={register}
                control={control}
                options={tipoClassficacaoOrdenada}
                changeSelected={(selected) => {
                  setValue('tipo_classificacao', selected);
                }}
                isError={!!errors.tipo_classificacao}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default ClassificacaoParceiro;
