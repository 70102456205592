import React from 'react';
import DefaultLoader from '~/components/DefaultLoader';
import Search from '~/components/Search';

import { ButtonForm, Container } from './styles';
import FormDefault from '~/components/FormDefault';
import InputRadioButton from '~/components/Inputs/InputRadioButton';
import { tiposDePessoa } from './defaultData';
import { Spinner, Tab, Tabs } from 'react-bootstrap';

import { useClienteContext } from './ClienteContext';
import { AbaGeral } from './Components/AbaGeral';
import { AbaCreditoProfissional } from './Components/AbaCreditoProfissional';
import { AbaFamiliaAutorizados } from './Components/AbaFamiliaAutorizados';
import { AbaCondicao } from './Components/AbaCondicao';
import { AbaAdministrativo } from './Components/AbaAdministrativo';
import useAuth from '~/hooks/useAuth';
import Tooltip from '@atlaskit/tooltip';
import { FaCheck } from 'react-icons/fa';

const ClienteContent: React.FC = () => {
  const { user } = useAuth();

  const {
    loader,
    loadingActive,
    isUpdate,
    setUpdate,
    showSearch,
    setShowSearch,
    tab,
    setTab,
    codClienteData,
    tipoPessoa,
    setClickSubmit,
    setValue,
    getValues,
    watch,
    onRowClick,
    resetFormData,
    changeTipoPessoa,
    onSubmit,
    onDelete,
    moduloAdmin,
    getConvenios,
    handleAtivarCliente,
  } = useClienteContext();

  const flg_inativo_client = watch('flg_inativo_client');

  // Botão Customizável para Ativar Cliente
  const ButtonAtivar = () => {
    return (
      <Tooltip position="bottom" content="Ativar">
        <ButtonForm onClick={handleAtivarCliente} disabled={loadingActive}>
          {loadingActive ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>
              <FaCheck /> Ativar
            </>
          )}
        </ButtonForm>
      </Tooltip>
    );
  };

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={21}
          newData={() => {
            setValue('tipoPessoa', 0);
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <>
          <FormDefault
            title={
              isUpdate
                ? `Cadastro de Cliente - ${getValues('name')}`
                : 'Cadastro de Cliente'
            }
            codigoRegistro={[
              {
                des_campo: 'Código',
                value: codClienteData,
              },
            ]}
            codTela={21}
            onSave={async () => {
              await onSubmit();
              setClickSubmit(true);
            }}
            onCancel={() => {
              resetFormData();
              setShowSearch(true);
            }}
            isUpdate={isUpdate}
            onNew={() => {
              setUpdate(false);
              resetFormData();
              getConvenios();
            }}
            onDelete={() => onDelete()}
            onClearFields={() => resetFormData()}
            onReturnSearch={() => {
              resetFormData();
              setUpdate(false);
              setShowSearch(true);
            }}
            showSwitch={false}
            labelButtonTrash="Inativar"
            deleteMessage="Deseja realmente inativar o registro?"
            isDelete={!flg_inativo_client}
            customButtonsUpdate={flg_inativo_client && <ButtonAtivar />}
          >
            <div className="row mb-3">
              <div className="col-12">
                <InputRadioButton
                  name="tiposDePessoa"
                  value={tipoPessoa}
                  label="Tipo de Pessoa"
                  options={tiposDePessoa}
                  isDisabled={isUpdate}
                  onChange={(val: string | number | undefined) => {
                    changeTipoPessoa(Number(val));
                    setValue('tipoPessoa', Number(val));
                  }}
                />
              </div>
            </div>
            <div className="containerTab">
              <Tabs
                id="controlled-tab-cliente"
                activeKey={tab}
                onSelect={(k) => setTab(k)}
              >
                <Tab eventKey="geral" title="Geral">
                  <AbaGeral />
                </Tab>
                <Tab eventKey="credprof" title="Crédito/Profissional">
                  <AbaCreditoProfissional />
                </Tab>
                <Tab eventKey="famaut" title="Família/Autorizados">
                  <AbaFamiliaAutorizados />
                </Tab>
                <Tab eventKey="condicao" title="Condição">
                  <AbaCondicao />
                </Tab>
                {moduloAdmin && user.flg_superadmin && (
                  <Tab eventKey="administrativo" title="Administrativo">
                    <AbaAdministrativo />
                  </Tab>
                )}
              </Tabs>
            </div>
          </FormDefault>
        </>
      )}
    </Container>
  );
};

export default ClienteContent;
